import { forwardRef, type ForwardRefRenderFunction } from "react";
import type { FieldError as HookFormFieldError } from "react-hook-form";
import styled, { css } from "styled-components";
import StatusMessage from "../StatusMessage/StatusMessage";
import Text from "../Text/Text";

interface StyledFieldRowProps {
  hasHint?: boolean;
  isInline?: boolean;
}

const StyledFieldRow = styled.div<StyledFieldRowProps>`
  display: flex;
  min-width: 0;
  align-items: center;
  justify-content: space-between;
  gap: 0.75rem;
  ${(p) => p.theme.typography.variants.bodyDefault}

  ${(p) =>
    !p.isInline &&
    css`
      min-height: 1.625rem;
    `}

  ${(p) =>
    p.hasHint &&
    css`
      align-items: start;
    `}
`;

const StyledLabel = styled.div`
  display: grid;
  flex: 1;
  gap: 0.25rem;
`;

interface StyledControlProps {
  hasHint?: boolean;
}

const StyledControl = styled.div<StyledControlProps>`
  display: flex;
  min-width: 0;
  align-items: center;
  gap: 0.375rem;
  ${(p) =>
    p.hasHint &&
    css`
      padding-top: 0.375rem;
    `}
`;

export interface FieldRowProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  constrain?: boolean;
  "data-testid"?: string;
  disabled?: boolean;
  error?: HookFormFieldError | string;
  hint?: React.ReactNode;
  htmlFor?: string;
  id?: string;
  /**
   * When inline, the FieldRow will not take up its usual minimum height. This
   * could probably be called something else, proposals welcome.
   */
  isInline?: boolean;
  label: React.ReactNode;
}

const FieldRow: ForwardRefRenderFunction<HTMLDivElement, FieldRowProps> = (
  {
    children,
    "data-testid": dataTestId,
    disabled,
    error,
    hint,
    htmlFor,
    id,
    isInline,
    label,
    ...props
  }: FieldRowProps,
  ref,
) => {
  let errorMessage: string | undefined;
  if (typeof error === "string") {
    errorMessage = error;
  } else if (error) {
    errorMessage = error.message;
  }

  const hasHint = !!hint;

  return (
    <StyledFieldRow
      {...props}
      hasHint={hasHint}
      isInline={isInline}
      ref={ref}
      data-testid={dataTestId}
    >
      <StyledLabel>
        <Text
          as="label"
          htmlFor={htmlFor}
          id={id}
          size="label"
          variant={disabled ? "disabled" : "weak"}
          data-testid={dataTestId && `${dataTestId}-label`}
        >
          {label}
          {hint && (
            <Text size="footnote" variant="weak">
              {hint}
            </Text>
          )}
          {errorMessage && (
            <StatusMessage
              data-testid={dataTestId && `${dataTestId}-error`}
              variant="error"
            >
              {errorMessage}
            </StatusMessage>
          )}
        </Text>
      </StyledLabel>
      <StyledControl hasHint={hasHint}>{children}</StyledControl>
    </StyledFieldRow>
  );
};

export default forwardRef(FieldRow);
